import { withDependencies } from '@wix/thunderbolt-ioc'
import { ILogger, LoggerSymbol, ExperimentsSymbol, SdkHandlersProvider, Experiments } from '@wix/thunderbolt-symbols'

export const FedopsSdkHandlersProvider = withDependencies(
	[LoggerSymbol, ExperimentsSymbol],
	(logger: ILogger, experiments: Experiments): SdkHandlersProvider => {
		return {
			getSdkHandlers: () => ({
				registerWidgets(widgetAppNames: Array<string>) {
					if (!experiments['specs.thunderbolt.platformWebVitals']) {
						return
					}
					logger.registerPlatformWidgets(widgetAppNames)
				},
			}),
		}
	}
)
