import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'
import { dashboardWixCodeSdkHandlersSSR } from './sdk/dashboardSdkProviderSSR'

export const site: ContainerModuleLoader = (bind) => {
	if (process.env.browser) {
		bind(WixCodeSdkHandlersProviderSym).to(require('./sdk/dashboardSdkProvider').dashboardWixCodeSdkHandlers)
	} else {
		bind(WixCodeSdkHandlersProviderSym).to(dashboardWixCodeSdkHandlersSSR)
	}
}

export * from './symbols'
export * from './types'
