import { withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider, LoggerSymbol, ILogger } from '@wix/thunderbolt-symbols'
import { SiteMembersWixCodeSdkHandlers, LegacyMemberDTO } from '../types'
import { ISiteMembersApi, SiteMembersApiSymbol, PrivacyStatus, INTERACTIONS } from 'feature-site-members'

const SiteMembersWixCodeSdkHandlersFactory = (
	{
		login,
		promptLogin,
		promptForgotPassword,
		applySessionToken,
		getMemberDetails,
		register,
		registerToUserLogin,
		registerToMemberLogout,
		logout,
		handleOauthToken,
	}: ISiteMembersApi,
	logger: ILogger
): SdkHandlersProvider<SiteMembersWixCodeSdkHandlers> => {
	return {
		getSdkHandlers() {
			return {
				async login(email, password, options) {
					logger.interactionStarted(INTERACTIONS.CODE_LOGIN)
					const response = await login(email, password, options)
					logger.interactionEnded(INTERACTIONS.CODE_LOGIN)
					return response
				},
				applySessionToken,
				promptForgotPassword,
				async promptLogin(options) {
					const { member } = await promptLogin(options)
					return member as LegacyMemberDTO // TODO: Maybe consolidate these almost identical types?
				},
				async register(email, password, options) {
					const { member, approvalToken, status } = await register(
						email,
						password,
						options?.contactInfo || { customFields: [] },
						options.privacyStatus || PrivacyStatus.PRIVATE
					)

					return {
						status,
						approvalToken,
						user: member,
					}
				},
				registerToUserLogin,
				registerToMemberLogout,
				logout,
				getMemberDetails,
				handleOauthToken,
			}
		},
	}
}

export const siteMembersWixCodeSdkHandlers = withDependencies(
	[SiteMembersApiSymbol, LoggerSymbol],
	SiteMembersWixCodeSdkHandlersFactory
)
